<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
    fullscreen
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="accent" dark>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }}
            Entrenamiento
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.planes.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.planes.single')"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="item.plan"
                            :placeholder="$t('app.planes.single')"
                            :items="planes"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.metas.single') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.metas.single')"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="item.goal"
                            :placeholder="$t('app.metas.single')"
                            :items="metas"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.headers.points') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.points')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.points"
                            :placeholder="$t('app.headers.points')"
                            :error-messages="errors"
                            type="number"
                            required
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('app.headers.weeks') }}

                  <v-btn
                    small
                    outlined
                    @click="openWeekAdd"
                  >
                    <v-icon left>mdi-plus-circle</v-icon>

                    {{ $t('app.buttons.add') }}
                  </v-btn>
                </v-list-item-title>

                <v-data-table
                  :headers="weeksHeaders"
                  :items="item.weeks"
                  :items-per-page="10"
                  :custom-filter="customFilter"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-col class="d-flex justify-end">
                      <v-btn
                        icon
                        @click="openWeekEdit(item)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        @click="openWeekDelete(item)"
                      >
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                </v-data-table>
              </v-list-item-content>
            </v-list-item>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <weeks-add></weeks-add>
    <weeks-edit />
    <week-delete></week-delete>
  </v-dialog>
</template>

<script>
  import customFilter from '@/plugins/customFilter'

  export default {
    name: 'TrainingEdit',
    mixins: [customFilter],
    components: {
      WeeksAdd: () => import("../weeks/WeekAddAlert"),
      WeeksEdit: () => import("../weeks/WeekEdit"),
      WeekDelete: () => import("../weeks/WeekDelete"),
    },
    data () {
      return {
        dialog: false,
        item: {
          plan: '',
          goal: "",
          points: '',
          weeks: []
        },
        planes: [],
        metas: [],
        usuarios: [],
        weekModel: {
          days: [],
          name: ""
        },
        weeksHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
      }
    },
    mounted () {
      EventBus.$on('trainings-edit', async (item) => {
        this.toggleLoader()
        this.item   = await this.getItem(item.id)
        this.planes = await this.getEntity('plans')
        this.metas  = await this.getEntity('goals')

        this.toggleLoader()
        this.dialog = true
      })

      EventBus.$on('reload-items-weeks-edit', item => {
        this.item.weeks = this.item.weeks.map(w => w.id == item.id ? item : w)
      })

      EventBus.$on('reload-items-weeks-delete', item => {
        this.item.weeks = this.item.weeks.filter(w => w.id != item.id)
      })
    },
    methods: {
      openWeekAdd(){
        EventBus.$emit('weeks-add-alert', this.item)
      },
      openWeekEdit(item) {
        EventBus.$emit('weeks-edit', {
          week: item,
          training: this.item
        })
      },
      openWeekDelete (item) {
        EventBus.$emit('weeks-delete', item)
      },

      async getItem(id) {
        let data = {}
        await this.$http.get(route(`trainings/${id}?populate=deep`))
          .then(response => {
            if (response.body.data) {
              data = response.body.data
              data.weeks = data.weeks
                .sort((x, y) => (x.id - y.id))
            } else {
              this.processError(response.body)
            }
          }, error => this.processError(error))
        return data
      },
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.put(route(`trainings/${this.item.id}`), {
              data: this.item
            })
              .then(response => {
                if (response.body.data) {
                  //this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
